.modalContainer {
  min-width: unset;
  width: 320px;
  height: 540px;
  background-color: var(--colorOldTan1);
}
.modalContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 492px;
}

/* Submit Button */
.buttonSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.backButtonContainer {
  color: black;
  max-width: 2.5rem;
  background-color: var(--colorGray2);
}
.backButton:hover {
  color: white;
}
.nextButtonContainer {
  background-color: var(--colorGray10);
}
.nextButtonText {
  margin: 0;
  color: white;
}

/* Slider */
.slideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 272px;
  margin: auto;
  min-height: 460px;
}
.slideImage {
  width: 256px;
  height: 256px;
}
.slideHeading {
  composes: ui20SemiBold from '../../../../styles/typeography.css';
  margin: 0;
  text-align: center;
  margin-top: 2rem;
}
.slideDescription {
  composes: ui14Regular from '../../../../styles/typeography.css';
  margin: 0;
  text-align: center;
  margin-top: 1rem;
  color: var(--colorGray10);
}
.swiperContainer {
  width: 100%;
}
