.catalogListHorizontal:last-child {
  margin-bottom: 2.35rem;
}

.repBannersContainer {
  width: 100%;
}

.repBannersContainer > * + * {
  margin-top: 1px;
}

.buyerRecommendationsPanel {
  padding: 0 !important;
  margin-top: 32px !important;
}
.repRecommendationsPanel {
  padding: 0 !important;
  margin-top: 32px !important;
  margin-bottom: 64px;
}

/* Superdepartment section */

.superDepartmentSection {
  width: 100%;
}

.isTinted {
  background-color: var(--colorTan1);
}

/* Superdepartment cards  */

.superDepartmentCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.superDepartmentCard {
  margin: 1.5rem;
  max-width: 16rem;
}

.superDepartmentCardImage {
  width: 100%;
  object-fit: cover;
  border-radius: 0.1875rem;
}
.superDepartmentCardInfo {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.superDepartmentCardTitle {
  font-family: var(--fontFamilySans);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: #2d2d2d;
  margin: 0 0.5rem 0 0;
}
.superDepartmentCardDescription {
  font-family: var(--fontFamilySans);
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.008em;
  color: #9b9b9b;
  margin: 0;
}

@media (--viewportTabletMin) {
  .superDepartmentCards {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
  .superDepartmentCard {
    max-width: 22rem;
  }
}

.subDepartmentCards {
  padding-top: 1rem;
  padding-bottom: 4rem;
  flex-wrap: wrap;
  justify-content: center;
}
