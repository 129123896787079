/* Stats Section */
.section {
  width: 100%;
  background-color: #fbfbfa;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.stat {
  margin-bottom: 2rem;
}

.titles {
  margin-bottom: 1rem;
}

.title {
  composes: brand40 from 'styles/typeography.css';
  color: var(--colorBlue9);
  white-space: nowrap;
}

.subtitle {
  composes: ui16Medium from 'styles/typeography.css';
  color: black;
  margin: 0 0 0.25rem 0;
}

.text {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray6);
  margin: 0;
}

@media (--viewportTabletMin) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3rem;
    row-gap: 0;
  }
}

@media (--viewportTabletLandscapeMin) {
  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
  }
}
