.container {
  background-color: var(--colorGray3);
  min-height: 62px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--colorBlack2);
  composes: ui12Medium from '../../styles/typeography.css';
  width: 100vw;
}

.setup {
  background-color: var(--colorOldTan2);
}

.containerInner {
  width: 100%;
  max-width: 1136px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

@media only screen and (min-width: 1136px) {
  .containerInner {
    padding-left: 0;
    padding-right: 0;
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.iconContainer {
  width: 38px;
  height: 38px;
  color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  flex-shrink: 0;
}

.iconContainer.borderlessIcon {
  background-color: transparent !important;
  border: none;
}

.info .iconContainer {
  background-color: var(--colorBlue8);
}

.positive .iconContainer {
  background-color: var(--colorGreen8);
}

.error .iconContainer {
  background-color: var(--colorRed8);
}

.warning .iconContainer {
  background-color: var(--colorYellow6);
}

.setup .iconContainer {
  background-color: var(--colorYellow7);
}

.info .iconContainer.borderlessIcon {
  color: var(--colorBlue8);
}

.positive .iconContainer.borderlessIcon {
  color: var(--colorGreen8);
}

.error .iconContainer.borderlessIcon {
  color: var(--colorRed8);
}

.warning .iconContainer.borderlessIcon {
  color: var(--colorYellow6);
}

.setup .iconContainer.borderlessIcon {
  color: var(--colorYellow7);
}

.content {
  margin-right: 16px;
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
}

.top {
  align-items: flex-start;
}
.center {
  align-items: center;
}
.bottom {
  align-items: flex-end;
}

@media (--viewportPhoneMax) {
  .linkContainerMobile {
    display: flex;
  }

  .linkNormal {
    display: none;
  }

  .containerInner {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (--viewportTabletMin) {
  .linkContainerMobile {
    display: none;
  }
}
