.container {
  display: flex;
  position: relative;
  justify-content: flex-start;
}
.subDepartmentContainer {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 1rem 0 1rem;
}
.subDepartmentImage {
  width: 96px;
  height: 96px;
  border-radius: 48px;
  object-fit: cover;
  margin-bottom: 0.75rem;
}
.subDepartmentName {
  composes: ui14Medium from 'styles/typeography.css';
  color: var(--colorGray11);
  text-transform: capitalize;
}
