.container {
  --mobileMaxInnerWidth: 17rem;
}

.isButtonLocked {
  pointer-events: none;
}

/* Base styles / Mobile */
.container,
.container.isMobilePreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--colorTan2);
  overflow: hidden;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
}
.container.isHomepageBanner {
  border-radius: 0;
}

.cover {
  overflow: hidden;
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 141.42%; /* ISO 216 paper size apect ratio, ie. A4 paper */
  min-width: 16rem;
  max-width: var(--mobileMaxInnerWidth);
  margin: 0 0 1.5rem 0;
}

.coverImage {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  border-radius: 0.1875rem;
}

.text {
  width: 100%;
  max-width: var(--mobileMaxInnerWidth);
  display: flex;
  text-align: left;
  flex-direction: column;
}

.spotlight {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorGold);
  text-transform: capitalize;
}

.spotlightDepartment {
  opacity: 0.5;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.headline {
  display: block;
  composes: ui28Bold from 'styles/typeography.css';
  color: var(--colorGray11);
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 1rem 0 0.5rem 0;
}

/* Composes is not allowed on multiple / nested selectors. */
.isHomepageBanner .headline {
  font-family: var(--fontFamilySans);
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 38px;
  letter-spacing: -0.96px;
}

.bodyText {
  display: block;
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray9);
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin: 0 0 1.5rem 0;
}

@media (--viewportTabletMin) {
  .container {
    flex-direction: row;
    align-items: stretch;
    border-radius: 0.125rem;
    padding: 0;
  }
  .container.isHomepageBanner {
    padding-top: 4rem;
    padding-bottom: 4rem;
    justify-content: center;
  }

  /* Catalog Cover */
  .cover {
    margin: 0;
  }
  .coverImage {
    border-radius: 0;
  }
  .isHomepageBanner .coverImage {
    border-radius: 0.125rem;
  }

  /* Promotion Content */
  .text {
    max-width: 30rem;
    padding: 2rem;
  }
  .isHomepageBanner .text {
    padding: 0 0 0 2rem;
  }

  .spotlight {
    flex-direction: row;
    margin-bottom: 0;
  }
  .spotlightDepartment {
    margin-right: 0.5rem;
  }

  .content {
    flex-grow: 1;
  }
}

/* These rules a duplicated here for the mobile preview beacuse the above media query requires these are declared after */
.isMobilePreview .cover {
  margin: 0 0 1.5rem 0;
}

.isMobilePreview .coverImage {
  border-radius: 0.125rem;
}

.isMobilePreview .text {
  max-width: var(--mobileMaxInnerWidth);
  padding: 0;
}

.isMobilePreview .spotlight {
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.isMobilePreview .spotlightDepartment {
  margin-right: 0;
}
