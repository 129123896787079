.container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
  overflow: auto;
}

.headerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.moreLink {
  margin-left: 3rem;
}

.title {
  composes: ui28SemiBold from 'styles/typeography.css';
  text-transform: capitalize;
  margin-bottom: 0;
  text-align: left;
  color: var(--colorBlack);
}

.showAllCatalogsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.showAllCatalogs {
  display: none;
}

.showAllCatalogsMobile {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-right: 4px;
  color: var(--colorGreenDark2);
}

.catalogsSection {
  max-width: 100%;
}

.catalogsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.promoBanner {
  grid-column: 1 / span 2;
  align-self: flex-start;
}

@media (--viewportTabletMin) {
  .showAllCatalogsMobile {
    display: none;
  }
  .showAllCatalogs {
    display: inline-block;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    margin-right: 4px;
    color: var(--colorGreenDark2);
  }
  .catalogsWrapper {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
  }
  .promoBanner {
    grid-column: 1 / span 3;
  }
}

@media (--viewportTabletMin) and (--viewportTabletMax) {
  /* On portrait tablet, show only three catalogs when promo present */
  .catalogsWrapper.hasPromotionSlot > .catalogListItem:not(:nth-child(-n + 4)) {
    display: none;
  }
}

@media (--viewportTabletLandscapeMin) {
  .catalogsWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (--viewportTabletLandscapeMin) and (--viewportTabletLandscapeMax) {
  /* show only 4 catalogs on landscape tablets */
  .catalogsWrapper > .catalogListItem:not(:nth-child(-n + 4)) {
    display: none;
  }
  /* show one catalogs when promo present */
  .catalogsWrapper.hasPromotionSlot > .catalogListItem:not(:nth-child(-n + 2)) {
    display: none;
  }
}

@media (--viewportDesktopMin) {
  .catalogsWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  /* show only 5 catalogs on desktop *max width container */
  .catalogsWrapper > .catalogListItem:not(:nth-child(-n + 5)) {
    display: none;
  }
  /* show only 2 catalogs when promo present */
  .catalogsWrapper.hasPromotionSlot > .catalogListItem:not(:nth-child(-n + 3)) {
    display: none;
  }
}

/* On mobile, show onlu four catalogs when promo present */
.catalogsWrapper.hasPromotionSlot > .catalogListItem:not(:nth-child(-n + 5)) {
  display: none;
}
