.section {
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.innerContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.info {
  margin-bottom: 3rem;
}
.title {
  composes: brand40 from 'styles/typeography.css';
  margin-bottom: 1rem;
}
.text {
  composes: ui14Medium from 'styles/typeography.css';
  margin-bottom: 2rem;
}
.image {
  border-radius: 4px;
}

/* Tablet */
@media (--viewportTabletMin) {
  .section {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .innerContainer {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .info,
  .image {
    width: calc(50% - 1.5rem);
  }
  .info {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
  .image {
    aspect-ratio: 1;
    object-fit: cover;
  }
}

@media (--viewportTabletLandscapeMin) {
  .image {
    aspect-ratio: unset;
  }
}

/* Kinds */

.section.default {
  background-color: #19587a;
}
.title.default {
  color: white;
}
.text.default {
  color: white;
}
.button.default,
.button.default:hover {
  color: #19587a;
}

.section.secondary {
  background-color: var(--colorTan2);
}
.title.secondary,
.text.secondary {
  color: var(--colorGray11);
}
