.panelSection {
  width: 100%;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: var(--colorTan1);
}

.swiperContainer {
  overflow: visible;
}

.sectionTitle {
  composes: brand28 from '../../../../styles/typeography.css';
}

.sectionDescription {
  composes: ui14Regular from '../../../../styles/typeography.css';
}

.testimonialContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  cursor: grab;
}

.testimonialContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
}

.testimonialImage {
  max-width: 8rem;
  border-radius: 3px;
}

@media (--viewportTabletMin) {
  .testimonialContainer {
    flex-direction: row;
    align-items: flex-start;
  }

  .testimonialContent {
    margin-top: 0;
    margin-left: 2rem;
  }
}
