.bannerContainer {
  width: 100vw;
  background-color: var(--colorOldTan2);
  display: flex;
  padding: 24px 0;
  justify-content: center;
}

.bannerInnerContainer {
  display: flex;
}

.mainMessageContainer {
  display: flex;
  flex: 0.8;
}

.mainMessageHeader {
  composes: ui14SemiBold from 'styles/typeography.css';
  margin-bottom: 8px;
}

.mainMessage {
  max-width: 36em;
  color: var(--colorGray7);
}

.bannerImageContainer {
  margin-right: 32px;
  min-width: 112px;
  display: flex;
}

.bannerImage {
  width: 112px;
  height: 90px;
}

.dismissContainer {
  display: flex;
  flex: 0.2;
  justify-content: flex-end;
}

.dismissButton {
  composes: ui14Medium from 'styles/typeography.css';
  color: var(--colorGray10) !important;
}

.hideForMobile {
  display: none;
}

.linksContainer {
  display: flex;
  flex-direction: column;
}

.externalLink {
  color: var(--colorButtonLinkText);
  padding: 0;
  width: fit-content;
}

.transparentLink {
  background: transparent !important;
}

@media (--viewportPhoneMax) {
  .bannerImageContainer {
    display: none;
  }

  .mainMessageContainer {
    flex: 0.9;
  }

  .dismissContainer {
    flex: 0.1;
  }

  .externalLink:not(:first-child) {
    margin-top: 10px;
  }
}

@media (--viewportTabletMin) {
  .hideForMobile {
    display: inline;
  }
}

@media (--viewportDesktopMin) {
  .hideForMobile {
    display: inline;
  }
}
