.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorTan2);
  width: 100%;
  margin-top: -12px;
  margin-bottom: 13px;
}

.bannerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  max-width: 1184px;
  width: 100%;
}

.sellerDetailsContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.logoImage {
  width: 48px;
  height: 48px;
  border-radius: 48px;
  object-fit: cover;
}

.textContent {
  composes: ui12Regular from 'styles/typeography.css';
}

.completeOrder {
  composes: ui12Medium from 'styles/typeography.css';
  margin-bottom: 0.125rem;
}

.displayName {
  composes: ui12SemiBold from 'styles/typeography.css';
}

.subtext {
  color: var(--colorGray8);
  margin-bottom: 0;
}
